import styled from 'styled-components'
import { motion } from 'framer-motion'

import { media } from '../../src/utils/style-utils'
import { CardAspectStyles, CardPadding, CardStyles } from '../../src/components/Card'
import { FullScreenCardGridStyles } from '../../src/layout/grid/Grid'
import { useCardViewportScaleAnimation } from '../../src/utils/hooks/use-card-viewport-scale-animation'

// Animation that makes text slide down to its final position
const HEADING_ANIMATION = {
	start: {
		opacity: 0,
		lineHeight: '0px',
	},
	end: {
		opacity: 1,
		lineHeight: '108%',
	},
}

const HEADING_WRAPPER_ANIMATION = {
	start: {
		top: '-25%',
	},
	end: {
		top: 0,
	},
}

const TRANSITION = {
	duration: 1.5,
	delay: 0.5,
}

const FullWidthVideo = ({ slice }) => {
	return (
		<Grid>
			<Container>
				<HeadingWrapper
					initial={HEADING_WRAPPER_ANIMATION.start}
					animate={HEADING_WRAPPER_ANIMATION.end}
					transition={TRANSITION}
				>
					<Heading
						initial={HEADING_ANIMATION.start}
						animate={HEADING_ANIMATION.end}
						transition={TRANSITION}
					>
						{slice.primary.heading}
					</Heading>
				</HeadingWrapper>
				<Video
					src={slice.primary.video.url}
					poster={slice.primary.video_fallback.url}
					autoPlay
					loop
					muted
					playsInline
				/>
			</Container>
		</Grid>
	)
}

const Container = styled(motion.div)`
	grid-column: 1 / -1;
	width: 100%;
	max-height: 930px;
	position: relative;
	${CardStyles}
	${CardAspectStyles}

	${media.desktopHuge`
		max-height: 1350px;
	`}

	${media.desktopXl`
		max-height: 1150px;
	`}

	${media.desktopMd`
		max-height: 800px;
	`};

	${media.tablet`
		aspect-ratio: 16/13;
		max-height: unset;
	`}

	${media.phone`
		max-height: 88vh;
	`}
`
const HeadingWrapper = styled(motion.div)`
	padding-inline: calc(${CardPadding} / 2);
	position: absolute;
	height: 100%;
	display: flex;
	align-items: center;

	${media.tablet`
	padding-inline: ${CardPadding};
	`}

	${media.tabletSm`
	align-items: flex-start;
	padding-top: var(--spacer-xl);
		top: var(--spacer-base);
	`}

	${media.phone`
		display: none;
	`}
`

const Heading = styled(motion.h2)`
	margin: 0;
	overflow: visible;
	color: white;
	max-width: 12ch;
	line-height: 108%;
	grid-column: 2 / -2;

	font-size: clamp(6vw, 5rem, 5rem);

	${media.tablet`
		font-size: clamp(6vw, 9vw, 5rem);
	`};

	${media.phone`
		font-size: 13vw;
	`}

	${media.phoneXs`
		font-size: 12vw;
	`}
`

const Video = styled.video`
	object-fit: cover;
	width: 100%;
	height: 100%;
	grid-column: 1 / -1;

	${media.phone`
		width: 100%;
		aspect-ratio: 9/16;
	`}
`

const Grid = styled.div`
	${FullScreenCardGridStyles}
`

export default FullWidthVideo
